'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../types/dist/index.js
var require_dist = __commonJS({
  "../types/dist/index.js"(exports, module2) {
    "use strict";
    "use client";
    var __defProp2 = Object.defineProperty;
    var __getOwnPropDesc2 = Object.getOwnPropertyDescriptor;
    var __getOwnPropNames2 = Object.getOwnPropertyNames;
    var __hasOwnProp2 = Object.prototype.hasOwnProperty;
    var __export2 = (target, all) => {
      for (var name in all)
        __defProp2(target, name, { get: all[name], enumerable: true });
    };
    var __copyProps2 = (to, from, except, desc) => {
      if (from && typeof from === "object" || typeof from === "function") {
        for (let key of __getOwnPropNames2(from))
          if (!__hasOwnProp2.call(to, key) && key !== except)
            __defProp2(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc2(from, key)) || desc.enumerable });
      }
      return to;
    };
    var __toCommonJS2 = (mod) => __copyProps2(__defProp2({}, "__esModule", { value: true }), mod);
    var src_exports2 = {};
    __export2(src_exports2, {
      Actions: () => Actions,
      SPECIALITIES: () => SPECIALITIES,
      VERTICALS: () => VERTICALS2
    });
    module2.exports = __toCommonJS2(src_exports2);
    var SPECIALITIES = [
      "gyn",
      "hud",
      "pediatrician",
      "fysio",
      "kiro",
      "midwife",
      "PT",
      "plastikk"
    ];
    var Actions = /* @__PURE__ */ ((Actions2) => {
      Actions2["SelectVertical"] = "select_vertical_v2";
      Actions2["SelectSpeciality"] = "select_speciality_v2";
      Actions2["SelectLocation"] = "select_location_v2";
      Actions2["SelectClinic"] = "select_clinic_v2";
      Actions2["SelectPractitioner"] = "select_practitioner_v2";
      Actions2["SkipSelectPractitioner"] = "skip_select_practitioner_v2";
      Actions2["OpenPractitionerInfo"] = "open_practitioner_info";
      Actions2["OpenMap"] = "open_map_v2";
      Actions2["SelectTime"] = "select_time_v2";
      Actions2["Permissions"] = "permissions_v2";
      Actions2["SelectPersonalDetails"] = "select_personal_details_v2";
      Actions2["Confirm"] = "confirm_booking_v2";
      Actions2["CompletedBooking"] = "completed_booking_v2";
      Actions2["ClickedBookAgain"] = "clicked_book_again_v2";
      Actions2["SelectDateButtonDisabled"] = "select_date_button_disabled";
      Actions2["ClickAppStore"] = "click_app_store";
      Actions2["ClickPlayStore"] = "click_play_store";
      Actions2["ClickNextAvailable"] = "click_next_available";
      Actions2["ExitFlow"] = "exit_flow_v2";
      Actions2["GoBack"] = "go_back_v2";
      Actions2["AddPartner"] = "add_partner_v2";
      Actions2["BookAppointmentError"] = "book_appointment_error_v2";
      Actions2["ClinicSortOptionClicked"] = "option_clicked_v2";
      Actions2["CountryCodePicked"] = "country_code_picked_v2";
      Actions2["CountryCodePressed"] = "country_code_pressed_v2";
      Actions2["DeleteCard"] = "delete_card_v2";
      Actions2["InitializePayment"] = "initialize_payment_v2";
      Actions2["InitializedPayment"] = "initialized_payment_v2";
      Actions2["InitializePaymentError"] = "initialize_payment_error_v2";
      Actions2["PaymentCompleted"] = "payment_completed_v2";
      Actions2["PaymentError"] = "payment_error_v2";
      Actions2["VippsRedirectInitialized"] = "vipps_redirect_initialized_v2";
      Actions2["VippsRedirectFailed"] = "vipps_redirect_failed_v2";
      Actions2["VippsPaymentCancelled"] = "vipps_payment_cancelled_v2";
      Actions2["VippsPaymentCompleted"] = "vipps_payment_completed_v2";
      Actions2["UpdateUserPhone"] = "update_user_phone_v2";
      Actions2["VideoInfoClicked"] = "video_info_clicked_v2";
      Actions2["NewsletterAccept"] = "newsletter_accepted";
      Actions2["NewsletterReject"] = "newsletter_rejected";
      Actions2["NewsletterMissing"] = "newsletter_email_missing";
      Actions2["NewsletterSubmitted"] = "newsletter_email_submitted";
      Actions2["CreateMembershipError"] = "create_membership_error";
      Actions2["HomeAddPartners"] = "home_insurance&discount_add";
      Actions2["HomeAddPartnersSelected"] = "home_insurance&discount_add_selected";
      Actions2["HomeAddPartnersSelectedAdded"] = "home_insurance&discount_add_selected_added";
      Actions2["HomeAddPartnersSelectedError"] = "home_insurance&discount_add_selected_error";
      Actions2["HomeAddPartnersSelectedRequest"] = "home_insurance&discount_add_selected_request";
      Actions2["HomeContact"] = "home_contact";
      Actions2["HomeOnboarding"] = "home_onboarding";
      Actions2["HomePartnersExisting"] = "home_insurance&discount_existing";
      Actions2["HomePartnersExistingError"] = "home_insurance&discount_existing_error";
      Actions2["HomePartnersExistingRemove"] = "home_insurance&discount_existing_remove";
      Actions2["HomePartnersExistingRequestUpdate"] = "home_insurance&discount_existing_request_update";
      Actions2["HomePartnersExistingUpdated"] = "home_insurance&discount_existing_updated";
      Actions2["HomeReturn"] = "home_return";
      Actions2["HomeScreen"] = "home_screen";
      Actions2["HomeVideoHelp"] = "home_video_help";
      Actions2["LoginButtonPressed"] = "login_button_pressed";
      Actions2["LoginCancelButtonPressed"] = "login_cancel_button_pressed";
      Actions2["LoginCompleted"] = "login_completed";
      Actions2["LoginError"] = "login_error";
      Actions2["LoginFinished"] = "login_finished";
      Actions2["LoginSetPinCode"] = "login_setting_pin_code";
      Actions2["LogoutCompleted"] = "logout_completed";
      Actions2["LogoutError"] = "logout_error";
      Actions2["LogoutInitiated"] = "logout_initiated";
      Actions2["LogoutProceed"] = "logout_proceed";
      Actions2["LogoutPromptAnswered"] = "logout_prompt_answered";
      Actions2["ClickExorLive"] = "click_exor_live";
      Actions2["GoToExorLive"] = "go_to_exor_live";
      Actions2["CancelExorLive"] = "cancel_exor_live";
      Actions2["LoginForgotPin"] = "login_forgot_pin";
      Actions2["LoginPinAndBiometricsSuccess"] = "login_pin_and_biometrics_success";
      Actions2["PermissionRequested"] = "permission_requested";
      Actions2["PermissionsGranted"] = "permissions_granted";
      Actions2["PermissionsMissing"] = "permissions_missing";
      Actions2["PermissionsOverlay"] = "permissions_overlay";
      Actions2["PermissionsRedirectToSettings"] = "permissions_open_settings";
      Actions2["PermissionsRedirectToSettingsFailed"] = "permissions_open_settings_failed";
      Actions2["ProfileBiometrics"] = "profile_pin&biometrics";
      Actions2["ProfileBiometricsChangePinCode"] = "profile_pin&biometrics_pincode";
      Actions2["ProfileBiometricsSetBiometrics"] = "profile_pin&biometrics_biometrics_activate";
      Actions2["ProfileBiometricsUnsetBiometrics"] = "profile_pin&biometrics_biometrics_deactivate";
      Actions2["ProfileContact"] = "profile_contact";
      Actions2["ProfileDetailsCountryCodePicked"] = "profile_personal_information_country_code_picked";
      Actions2["ProfileDetailsCountryCodePressed"] = "profile_personal_information_country_code_pressed";
      Actions2["ProfileInfoSaveError"] = "Profile_Save_Error";
      Actions2["ProfileInfoSaved"] = "Saved_Personal_Info";
      Actions2["ProfileOnboarding"] = "profile_onboarding";
      Actions2["ProfilePartners"] = "home_insurance&discount";
      Actions2["ProfilePaymentCard"] = "profile_payment_card";
      Actions2["ProfilePermissions"] = "profile_permissions";
      Actions2["ProfileReturn"] = "profile_return";
      Actions2["ProfileSavedDetails"] = "profile_personal_information_saved";
      Actions2["ProfileScreen"] = "profile_screen";
      Actions2["ProfileVideoHelp"] = "profile_video_help";
      Actions2["VersionDeprecationWarning"] = "version_deprecation_warning";
      Actions2["VersionDeprecationWarningAction"] = "version_deprecation_warning_action";
      Actions2["VideoAnsweredCall"] = "video_answered_call";
      Actions2["VideoAnsweredCallOld"] = "Answered_Video_Call";
      Actions2["VideoCallEnded"] = "video_call_ended";
      Actions2["VideoConnected"] = "video_connected";
      Actions2["VideoConnecting"] = "video_connecting";
      Actions2["VideoDeclined"] = "video_declined";
      Actions2["VideoDisconnected"] = "video_disconnected";
      Actions2["VideoEndedConsultationOld"] = "Ended_Video_Consultation";
      Actions2["VideoEnteredScreen"] = "video_entered_screen";
      Actions2["VideoError"] = "video_error";
      Actions2["VideoReceivedCall"] = "video_received_call";
      Actions2["VideoRecievedCallOld"] = "Recieved_Video_Call";
      Actions2["VideoSettingsChangeMicrophone"] = "video_settings_change_microphone";
      Actions2["VideoSettingsFetchingActiveAudio"] = "video_settings_fetching_active_audio";
      Actions2["VideoSettingsFlipCamera"] = "video_settings_flip_camera";
      Actions2["VideoSettingsToggleMuteMicrophone"] = "video_settings_toggle_mute_microphone";
      Actions2["VideoSummaryDisplayed"] = "video_displayed_summary";
      Actions2["VideoTryingToConnect"] = "video_trying_to_connect";
      Actions2["ApiError"] = "Api_Error";
      Actions2["AppError"] = "app_error";
      Actions2["ClickedPhysicalGuide"] = "clicked_physical_guide";
      Actions2["CloseVideoModal"] = "close_video_modal";
      Actions2["OpenVideoModal"] = "open_video_modal";
      Actions2["FutureDateError"] = "web_booking_profilePersonalInformation_futureDateError";
      Actions2["PastDateError"] = "web_booking_profilePersonalInformation_pastDateError";
      Actions2["PhoneVerificationError"] = "phoneVerificationError";
      Actions2["PhoneVerificationSuccess"] = "phoneVerificationSuccess";
      Actions2["PhoneVerificationSupport"] = "phoneVerificationSupport";
      return Actions2;
    })(Actions || {});
    var VERTICALS2 = [
      "covid",
      "dermatologist",
      "general",
      "mental-health",
      "eye-doctor",
      "gynecologist",
      "physical",
      "psychology",
      "specialist"
    ];
  }
});

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ALL_STEPS: () => ALL_STEPS,
  FeedbackSchema: () => FeedbackSchema,
  HelpSchema: () => HelpSchema,
  allQueryParams: () => allQueryParams,
  appLocales: () => appLocales,
  clinicsWithDistance: () => clinicsWithDistance,
  convertDistanceToUnit: () => convertDistanceToUnit,
  couldHaveSurcharge: () => couldHaveSurcharge,
  findNextAvailableTimeSlot: () => findNextAvailableTimeSlot,
  findNextAvailableTimeSlotByPractitioner: () => findNextAvailableTimeSlotByPractitioner,
  formatDate: () => formatDate,
  formatPrice: () => formatPrice,
  formatReadableDateTime: () => formatReadableDateTime,
  formatStartTime: () => formatStartTime,
  getAllParams: () => getAllParams,
  getAllParamsUpTo: () => getAllParamsUpTo,
  getAvailableTimeSlotOnSelectedDay: () => getAvailableTimeSlotOnSelectedDay,
  getCookieConsent: () => getCookieConsent,
  getDateInTimezone: () => getDateInTimezone,
  getDateTypeOfDay: () => getDateTypeOfDay,
  getDayTypeAndTimeOfDayFromSelectedTime: () => getDayTypeAndTimeOfDayFromSelectedTime,
  getDaysUntil: () => getDaysUntil,
  getDistanceToClinics: () => getDistanceToClinics,
  getFormattedAppointmentDateTime: () => getFormattedAppointmentDateTime,
  getFormattedBirthDate: () => getFormattedBirthDate,
  getFormattedCardNumber: () => getFormattedCardNumber,
  getFormattedPhoneNumber: () => getFormattedPhoneNumber,
  getFormattedSSN: () => getFormattedSSN,
  getFormattedWaitTime: () => getFormattedWaitTime,
  getISOString: () => getISOString,
  getIsEvening: () => getIsEvening,
  getLowestPrice: () => getLowestPrice,
  getNorwegianSurchargeInfoText: () => getNorwegianSurchargeInfoText,
  getNorwegianSurchargeLabel: () => getNorwegianSurchargeLabel,
  getPractitionerTimeSlots: () => getPractitionerTimeSlots,
  getPractitionersWithSortedTimeSlotsForSelectedDate: () => getPractitionersWithSortedTimeSlotsForSelectedDate,
  getServiceHasSurcharge: () => getServiceHasSurcharge,
  getSortedHours: () => getSortedHours,
  getStepFilledToNow: () => getStepFilledToNow,
  getTimeStamp: () => getTimeStamp,
  getUniqueDayList: () => getUniqueDayList,
  getVertical: () => getVertical,
  globalParams: () => globalParams,
  isIsoDate: () => isIsoDate,
  isPhoneNumberGoodEnough: () => isPhoneNumberGoodEnough,
  isTimeSlotInSelectedDay: () => isTimeSlotInSelectedDay,
  isTimeslotInTheFuture: () => isTimeslotInTheFuture,
  locales: () => locales,
  localesDateOnly: () => localesDateOnly,
  norwayTimeZone: () => norwayTimeZone,
  parseDay: () => parseDay,
  stepParams: () => stepParams,
  timeZone: () => timeZone,
  useBannerStore: () => useBannerStore,
  useClinicStore: () => useClinicStore,
  useCookieStore: () => useCookieStore,
  useDateStore: () => useDateStore,
  useEventListener: () => useEventListener_default,
  useIntersectionObserver: () => useIntersectionObserver,
  useIsomorphicLayoutEffect: () => useIsomorphicLayoutEffect_default,
  useNavigationStore: () => useNavigationStore,
  useNotificationStore: () => useNotificationStore,
  usePartnerStore: () => usePartnerStore,
  usePaymentStore: () => usePaymentStore,
  usePersonalDataStore: () => usePersonalDataStore,
  usePositionStore: () => usePositionStore,
  usePractitionerStore: () => usePractitionerStore,
  usePrevious: () => usePrevious,
  useServiceTreeStore: () => useServiceTreeStore,
  useToAndEndDate: () => useToAndEndDate,
  useUIStore: () => useUIStore,
  useWeekRanges: () => useWeekRanges
});
module.exports = __toCommonJS(src_exports);

// src/hooks/useEventListener.ts
var import_react2 = require("react");

// src/hooks/useIsomorphicLayoutEffect.ts
var import_react = require("react");
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? import_react.useLayoutEffect : import_react.useEffect;
var useIsomorphicLayoutEffect_default = useIsomorphicLayoutEffect;

// src/hooks/useEventListener.ts
function useEventListener(eventName, handler, element, options) {
  const savedHandler = (0, import_react2.useRef)(handler);
  useIsomorphicLayoutEffect_default(() => {
    savedHandler.current = handler;
  }, [handler]);
  (0, import_react2.useEffect)(() => {
    const targetElement = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }
    const eventListener = (event) => savedHandler.current(event);
    targetElement.addEventListener(eventName, eventListener, options);
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, options]);
}
var useEventListener_default = useEventListener;

// src/hooks/useIntersectionObserver.ts
var import_react3 = require("react");
function useIntersectionObserver(elementRef, {
  threshold = 0,
  root = null,
  rootMargin = "0%",
  freezeOnceVisible = false
}) {
  const [entry, setEntry] = (0, import_react3.useState)();
  const frozen = entry?.isIntersecting && freezeOnceVisible;
  const updateEntry = ([newEntry]) => {
    setEntry(newEntry);
  };
  (0, import_react3.useEffect)(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || frozen || !node)
      return;
    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return () => observer.disconnect();
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);
  return entry;
}

// src/hooks/usePrevious.ts
var import_react4 = require("react");
function usePrevious(state) {
  const ref = (0, import_react4.useRef)();
  (0, import_react4.useEffect)(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
}

// src/hooks/useToAndEndDate.ts
var import_react5 = require("react");
function useToAndEndDate(start, end) {
  const [startDate, setStartDate] = (0, import_react5.useState)(start);
  const [endDate, setEndDate] = (0, import_react5.useState)(end);
  return { setEndDate, setStartDate, startDate, endDate };
}

// src/hooks/useWeekRanges.ts
var import_date_fns3 = require("date-fns");
var import_nb3 = __toESM(require("date-fns/locale/nb"));
var import_chunk = __toESM(require("lodash/chunk"));
var import_react6 = require("react");

// src/utils/cookies.ts
var getCookies = (cookieString) => cookieString.split("; ").reduce((prev, current) => {
  const [name, ...value] = current.split("=");
  prev[name] = value.join("=");
  return prev;
}, {});
function getCookieConsent(cookieString) {
  const consentCookie = getCookies(cookieString).CookieConsent;
  if (consentCookie) {
    const parsedCookieConsent = JSON.parse(
      consentCookie.replace(/%2c/gi, ",").replace(/%27|'/g, '"').replace(/([{\\[,])\s*([a-zA-Z0-9_]+?):/g, '$1"$2":')
    );
    return parsedCookieConsent;
  }
}

// src/utils/date-time.ts
var import_nb = require("moment/locale/nb");
var import_date_fns = require("date-fns");
var import_en_US = __toESM(require("date-fns/locale/en-US"));
var import_nb2 = __toESM(require("date-fns/locale/nb"));
var import_date_fns_tz = require("date-fns-tz");
var import_moment = __toESM(require("moment"));
function formatDate(date) {
  return (0, import_date_fns_tz.format)(date, "dd.MM.yyyy");
}
function formatReadableDateTime(dateobj) {
  return (0, import_date_fns_tz.format)(dateobj, "d. MMMM 'kl.' HH:mm", { locale: import_nb2.default });
}
var formatStartTime = (personalDetails) => new Date(
  getTimeStamp(personalDetails.date, personalDetails.time)
).toISOString();
function getDaysUntil(date) {
  const startTime = (0, import_moment.default)(date).startOf("day");
  const today = (0, import_moment.default)().startOf("day");
  return startTime.diff(today, "days");
}
var parseDay = (date) => {
  const isoDate = `${date}T12:00:00.000Z`;
  if (isIsoDate(isoDate)) {
    return (0, import_date_fns.parseISO)(isoDate);
  }
  return /* @__PURE__ */ new Date();
};
var getDateInTimezone = (date = /* @__PURE__ */ new Date()) => (0, import_date_fns_tz.formatInTimeZone)(date, timeZone, "yyyy-MM-dd");
var getFormattedWaitTime = (date = /* @__PURE__ */ new Date(), locale = import_nb2.default) => {
  const now = /* @__PURE__ */ new Date();
  const duration = (0, import_date_fns.differenceInMinutes)(date, now);
  const coff = 1e3 * 60 * 5;
  const roundedNow = new Date(Math.floor(now.getTime() / coff) * coff);
  const durationNearest10 = Math.ceil(duration / 10) * 10;
  return {
    displayName: durationNearest10 <= 5 ? "ca. 5 min" : (0, import_date_fns.formatDistance)(date, roundedNow, {
      locale: { ...locale, formatDistance: formatDistanceCustom }
    }),
    // Wack hack to add 10 minutes to the duration, to make sure the time is always rounded up and added extra time.
    // Should handle this from the backend
    duration: durationNearest10 + 10
  };
};
var norwayTimeZone = "Europe/Oslo";
var appLocales = {
  nbApp: {
    ...import_nb2.default,
    formatRelative: (token) => ({
      lastWeek: "'Forrige' eeee 'kl' p",
      yesterday: "'I g\xE5r kl.' p",
      today: "'ca.' HH:mm",
      tomorrow: "'I morgen kl.' HH.mm",
      nextWeek: "dd. MMM 'kl.' HH.mm",
      other: "dd. MMMM 'kl.' HH.mm"
    })[token]
  }
};
var locales = {
  nb: {
    ...import_nb2.default,
    formatRelative: (token) => ({
      lastWeek: "'Forrige' eeee 'kl' p",
      yesterday: "'I g\xE5r kl.' p",
      today: "'I dag kl.' HH.mm",
      tomorrow: "'I morgen kl.' HH.mm",
      nextWeek: "dd. MMM 'kl.' HH.mm",
      other: "dd. MMMM 'kl.' HH.mm"
    })[token]
  },
  en: {
    ...import_en_US.default,
    formatRelative: (token) => ({
      lastWeek: "'Last' eeee 'at' HH:mm",
      yesterday: "'Yesterday at' HH:mm",
      today: "'Today at' HH:mm",
      tomorrow: "'Tomorrow at' HH:mm",
      nextWeek: "dd. MMM 'at' HH:mm",
      other: "dd. MMMM 'at' HH:mm"
    })[token]
  }
};
var localesDateOnly = {
  nb: {
    ...import_nb2.default,
    formatRelative: (token) => ({
      lastWeek: "'Forrige uke'",
      yesterday: "'I g\xE5r'",
      today: "'I dag'",
      tomorrow: "'I morgen'",
      nextWeek: "dd. MMM",
      other: "dd. MMMM"
    })[token]
  },
  en: {
    ...import_en_US.default,
    formatRelative: (token) => ({
      lastWeek: "'Last week'",
      yesterday: "'Yesterday",
      today: "'Today",
      tomorrow: "'Tomorrow",
      nextWeek: "dd. MMM",
      other: "dd. MMMM"
    })[token]
  }
};
var formatDistanceLocale = {
  lessThanXSeconds: "ca. {{count}}s",
  xSeconds: "ca. {{count}}s",
  halfAMinute: "ca. 30s",
  lessThanXMinutes: "ca. {{count}} min",
  xMinutes: "ca. {{count}} min",
  aboutXHours: "ca. {{count}} timer",
  xHours: "ca. {{count}} timer",
  xDays: "ca. {{count}} dager",
  aboutXWeeks: "ca. {{count}} uker",
  xWeeks: "{{count}} uker",
  aboutXMonths: "omtrent {{count}} m\xE5neder",
  xMonths: "{{count}} m\xE5neder",
  aboutXYears: "omtrent {{count}} \xE5r",
  xYears: "{{count}} \xE5r",
  overXYears: "over {{count}} \xE5r",
  almostXYears: "nesten {{count}} \xE5r"
};
function formatDistanceCustom(token, count, options) {
  options = options || {};
  const result = formatDistanceLocale[token].replace("{{count}}", count);
  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }
  return result;
}
var timeZone = process.env.GATSBY_TIMEZONE || "Europe/Oslo";
var getTimeStamp = (selectedDate, selectedTime) => {
  const date = /* @__PURE__ */ new Date(`${selectedDate}T${selectedTime}Z`);
  const offset = (0, import_date_fns_tz.getTimezoneOffset)(timeZone, date);
  return date.valueOf() - offset;
};
var getISOString = (date, time) => (/* @__PURE__ */ new Date(`${date}T${time}`)).toISOString();
function getFormattedBirthDate(date) {
  const [day, month, year] = date.split(/\//).map((d) => parseInt(d));
  return (0, import_date_fns_tz.format)(new Date(year, month > 0 ? month - 1 : 0, day), "dd.MM.yyyy");
}
var nextYearFormat = {
  nb: "dd. MMM yyyy, 'kl.' HH.mm",
  en: "dd. MMM yyyy, HH:mm"
};
var isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());
var getFormattedAppointmentDateTime = (date, time, locale, isAndroid = false) => {
  const selectedDate = isAndroid ? new Date(getTimeStamp(date, time)) : new Date(getISOString(date, time));
  if (!isValidDate(selectedDate))
    return "";
  if ((0, import_date_fns.isSameYear)(selectedDate, /* @__PURE__ */ new Date())) {
    return (0, import_date_fns.formatRelative)(selectedDate, /* @__PURE__ */ new Date(), {
      locale: locales[locale],
      weekStartsOn: 1
    });
  }
  return (0, import_date_fns_tz.format)(selectedDate, nextYearFormat[locale], {
    locale: locales[locale]
  });
};
var isIsoDate = (date) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date))
    return false;
  const d = new Date(date);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === date;
};

// src/utils/dayType.ts
var NATIONAL_HOLIDAYS_NORWAY = [
  "2024-12-24",
  // Julaften
  "2024-12-25",
  // 1. Juledag
  "2024-12-26",
  // 2. Juledag
  "2024-12-31",
  // Nyttårsaften
  "2025-01-01",
  // 1. nyttårsdag
  "2025-04-17",
  // Skjærtorsdag
  "2025-04-18",
  // Langfredag
  "2025-04-21",
  // 2. påskedag
  "2025-05-01",
  // Arbeiderenes dag
  "2025-05-17",
  // Grunnlovsdagen
  "2025-05-29",
  // Kristi Himmelfartsdag
  "2025-06-09",
  // 2. pinsedag
  "2025-12-24",
  // Julaften
  "2025-12-25",
  // 1. Juledag
  "2025-12-26",
  // 2. Juledag
  "2025-12-31",
  // Nyttårsaften
  "2026-01-01"
  // 1. nyttårsdag
];
function getDateTypeOfDay(selectedDay) {
  let date;
  try {
    if (!selectedDay || selectedDay.length === 0)
      throw new Error("Invalid date");
    date = new Date(selectedDay);
  } catch (error) {
    return "day";
  }
  const dayOfWeek = date.getDay();
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return "weekend";
  }
  if (NATIONAL_HOLIDAYS_NORWAY.includes(selectedDay)) {
    return "holiday";
  }
  return "day";
}
function getIsEvening(time) {
  const hour = parseInt(time?.split(":")[0], 10);
  return hour >= 18;
}
function getDayTypeAndTimeOfDayFromSelectedTime(date, time) {
  const defaultResult = {
    typeOfDay: "day",
    isEvening: false
  };
  if (!date || date.length !== 10) {
    return defaultResult;
  }
  if (!time || time.length !== 5) {
    return defaultResult;
  }
  return {
    typeOfDay: getDateTypeOfDay(date),
    isEvening: getIsEvening(time)
  };
}
var couldHaveSurcharge = (dayType, isEvening) => dayType !== "day" || isEvening;

// src/utils/distance.ts
var import_distance = __toESM(require("@turf/distance"));
var import_helpers = require("@turf/helpers");
var getDistanceToClinics = (clinic, currentCoords) => {
  if (!clinic.coordinates)
    return;
  const from = (0, import_helpers.point)([currentCoords.longitude, currentCoords.latitude]);
  const to = (0, import_helpers.point)([clinic.coordinates.lng, clinic.coordinates.lat]);
  return (0, import_distance.default)(from, to, { units: "kilometers" });
};
var convertDistanceToUnit = (distance2) => {
  const distanceToMeter = (0, import_helpers.convertLength)(
    distance2,
    "kilometers",
    "meters"
  ).toFixed(0);
  return distance2 < 1 ? `${distanceToMeter} m` : `${distance2.toFixed(1)} km`;
};
var clinicsWithDistance = (currentCoords, clinics) => clinics.map((clinic) => {
  const distance2 = getDistanceToClinics(clinic, currentCoords);
  return {
    ...clinic,
    distance: distance2
  };
});

// src/utils/get-vertical.ts
var import_types = __toESM(require_dist());
var getVertical = (findVertical) => import_types.VERTICALS.find((vertical) => findVertical.includes(vertical)) ?? import_types.VERTICALS[0];

// src/utils/params.ts
var import_use_query_params2 = require("use-query-params");

// src/lib/steps.ts
var import_use_query_params = require("use-query-params");
var ALL_STEPS = [
  {
    name: "Vertical step",
    key: "vertical-step",
    urlParams: [["vertical", import_use_query_params.StringParam]]
  },
  // Choose vertical among the five hard-coded. Psychology will only trigger a pop-up saying to download the app. Note that the QA section at the botton here is outdated: it explains differences between in-clinic and video appointments, and the link to FAQ does not work.
  {
    name: "Practitioner type step",
    key: "practitioner-type-step",
    urlParams: [
      ["speciality", import_use_query_params.StringParam],
      ["service", import_use_query_params.StringParam]
    ]
  },
  // Choose practitioner type and/or type of appointment (video/in-clinic), or city. If only one practitioner, go to next step. Practitioner types and clinics are gotten from Sanity (Specialists).
  //{
  //   name: 'Service type step',
  //   key: 'service-type-step',
  //   urlParams: [['service', StringParam]],
  // }, // Select "service" for a given practitioner type. The services are fetched based on service categories in WW: it gets all services within a category with the name equal to the "specialist" param.
  {
    name: "Location step",
    key: "location-step",
    urlParams: [["city", import_use_query_params.StringParam]]
  },
  {
    name: "Clinic step",
    key: "clinic-step",
    urlParams: [["clinicId", import_use_query_params.StringParam]]
  },
  {
    name: "Select practitioner step",
    key: "practitioner-step",
    urlParams: [["practitionerId", import_use_query_params.StringParam]]
  },
  {
    name: "Date/time step",
    key: "Date/Time-step",
    urlParams: []
  },
  {
    name: "Personal details step",
    key: "personal-details-step",
    urlParams: []
  },
  {
    name: "Confirm sms step",
    key: "confirm-sms-step",
    urlParams: []
  },
  {
    name: "Receipt step.",
    key: "receipt-step.",
    urlParams: []
  }
];

// src/utils/params.ts
var globalParams = {
  partner: import_use_query_params2.StringParam,
  partnerId: import_use_query_params2.StringParam,
  date: import_use_query_params2.StringParam,
  time: import_use_query_params2.StringParam,
  resourceId: import_use_query_params2.StringParam,
  device: import_use_query_params2.StringParam,
  deviceId: import_use_query_params2.StringParam
};
var stepParams = {
  vertical: import_use_query_params2.StringParam,
  speciality: import_use_query_params2.StringParam,
  service: import_use_query_params2.StringParam,
  city: import_use_query_params2.StringParam,
  clinicId: import_use_query_params2.StringParam
};
var allQueryParams = {
  ...globalParams,
  ...stepParams
};
var getUrlParamsFromSteps = (steps) => steps.reduce((acc, { urlParams }) => {
  for (const [key, value] of urlParams) {
    acc[key] = value;
  }
  return acc;
}, {});
var getUrlParamsUpToStep = (allSteps, length) => {
  const stepsTilNow = allSteps.slice(0, length);
  return getUrlParamsFromSteps(stepsTilNow);
};
var getAllParamsUpTo = (length) => getUrlParamsUpToStep(ALL_STEPS, length);
var getAllParams = () => ({
  ...getUrlParamsFromSteps(ALL_STEPS),
  ...globalParams
});
function getStepFilledToNow(steps, query) {
  let stepsFilledTillNow = 0;
  if (!Array.isArray(steps) || typeof query !== "object" || Array.isArray(query)) {
    return stepsFilledTillNow;
  }
  const paramsEachStep = steps.map(({ urlParams }) => [
    ...urlParams.map(([param, _]) => param).flat()
  ]);
  paramsEachStep.map((_, i) => {
    let stepFilledIn;
    const params = paramsEachStep[i];
    params.map((_2, j) => {
      stepFilledIn = !!query[params[j]];
    });
    if (stepFilledIn) {
      stepsFilledTillNow += 1;
    } else {
      return stepsFilledTillNow;
    }
  });
  return stepsFilledTillNow;
}

// src/utils/payments.ts
function formatPrice(price) {
  return price ? price / 100 : price;
}
function getFormattedCardNumber(cardNumber) {
  const lastFourDigits = cardNumber.slice(-4);
  return `**** ${lastFourDigits}`;
}
function getFormattedSSN(ssn) {
  return `${ssn.slice(0, 6)} ${ssn.slice(6)}`;
}
function getLowestPrice(services, links) {
  const servicePrices = services.map((service) => service.price);
  const linkPrices = links?.map(
    (link) => parseInt(link.additionalInfo.nb.replace(",-", "")) * 100
  ) ?? [];
  const allPrices = [...servicePrices, ...linkPrices];
  return Math.min(...allPrices);
}

// src/utils/phone.ts
var import_mobile = require("libphonenumber-js/mobile");
function isPhoneNumberGoodEnough(phoneNumber = "", countryCode) {
  const isPossibleNumber = (0, import_mobile.isPossiblePhoneNumber)(phoneNumber, countryCode);
  const isValidNumber = (0, import_mobile.isValidPhoneNumber)(phoneNumber, countryCode);
  return isValidNumber && isPossibleNumber;
}
function getFormattedPhoneNumber(phone, selectedCountry) {
  if (phone) {
    const phoneNumber = (0, import_mobile.parsePhoneNumber)(phone, selectedCountry);
    return phoneNumber.formatInternational();
  }
  return phone;
}

// src/utils/surcharge.ts
function getNorwegianSurchargeLabel(surchargeType, lowerCase = false) {
  const label = {
    evening: "Kveldstillegg",
    weekend: "Helgetillegg",
    holiday: "Helligdagstillegg"
  }[surchargeType];
  return lowerCase ? label.toLowerCase() : label;
}
var getNorwegianSurchargeInfoText = (type, price) => `*Inkl. ${getNorwegianSurchargeLabel(type, true)} p\xE5 ${formatPrice(price)},-`;
function getServiceHasSurcharge(service) {
  if (!service)
    return false;
  const weekendSurcharge = service?.weekendSurcharge ?? 0;
  const eveningSurcharge = service?.eveningSurcharge ?? 0;
  if (service.price <= 0)
    return false;
  return weekendSurcharge > 0 || eveningSurcharge > 0;
}

// src/utils/time-slot.ts
var import_date_fns2 = require("date-fns");
var import_date_fns_tz2 = require("date-fns-tz");
var isTimeslotInTheFuture = (timeslotStartTime, now) => {
  const startTime = (0, import_date_fns2.parseISO)(timeslotStartTime);
  return (0, import_date_fns2.isAfter)(startTime, now);
};
var isTimeSlotInSelectedDay = (timeslotStartTime, compareTo) => {
  const startTime = (0, import_date_fns2.parseISO)(timeslotStartTime);
  return (0, import_date_fns2.isSameDay)(startTime, compareTo);
};
function getUniqueDayList(timeSlots) {
  const uniqueDays = timeSlots.reduce(
    (acc, { startTime, isAvailable }) => {
      const splitDay = startTime.split("T")[0];
      if (acc[splitDay] === void 0) {
        return { ...acc, [splitDay]: isAvailable };
      } else if (!acc[splitDay] && isAvailable) {
        return { ...acc, [splitDay]: isAvailable };
      }
      return acc;
    },
    {}
  );
  return Object.entries(uniqueDays).sort(
    (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
  );
}
var getPractitionerTimeSlots = (id, timeSlots) => timeSlots.filter((timeSlot) => {
  return timeSlot.availableSpotsByPractitionerIds[id] > 0;
});
var getAvailableTimeSlotOnSelectedDay = ({
  selectedDay,
  timeSlots,
  practitionerId
}) => timeSlots?.filter(
  ({ startTime, availableSpotsByPractitionerIds, isAvailable }) => {
    const parsedISO = (0, import_date_fns2.parseISO)(startTime);
    const availableTimeSlots = (0, import_date_fns2.isSameDay)(parsedISO, parseDay(selectedDay)) && availableSpotsByPractitionerIds[practitionerId] > 0 && isAvailable;
    return availableTimeSlots;
  }
);
var findNextAvailableTimeSlotByPractitioner = (practitionerTimeslots, selectedDay, practitionerId) => {
  const timeSlotsOnSelectedDay = getAvailableTimeSlotOnSelectedDay({
    selectedDay,
    timeSlots: practitionerTimeslots,
    practitionerId
  });
  if (timeSlotsOnSelectedDay?.length > 0) {
    return timeSlotsOnSelectedDay[0];
  }
  return findNextAvailableTimeSlot(practitionerTimeslots, selectedDay);
};
var getPractitionersWithSortedTimeSlotsForSelectedDate = ({
  timeSlots,
  selectedDate,
  practitioners
}) => {
  return practitioners.map((practitioner) => {
    const practitionerTimeSlots = getPractitionerTimeSlots(
      practitioner.id,
      timeSlots
    );
    const now = /* @__PURE__ */ new Date();
    const timeSlotsInSelectedDateThatHaveNotPassed = practitionerTimeSlots.filter(({ startTime }) => {
      const parsedISO = (0, import_date_fns2.parseISO)(startTime);
      const startTimeInFuture = parsedISO > now;
      return getDateInTimezone(parsedISO) === selectedDate && startTimeInFuture;
    });
    const nextAvailableTimeSlot = findNextAvailableTimeSlotByPractitioner(
      practitionerTimeSlots,
      selectedDate,
      practitioner.id
    );
    return {
      ...practitioner,
      timeSlots: timeSlotsInSelectedDateThatHaveNotPassed,
      nextAvailableTimeSlotInDaysAhead: nextAvailableTimeSlot
    };
  });
};
var findNextAvailableTimeSlot = (timeSlots, selectedDay) => timeSlots.find(({ startTime, isAvailable }) => {
  const parsedISO = (0, import_date_fns2.parseISO)(startTime);
  const startTimeAfterSelectedDate = parsedISO > parseDay(selectedDay);
  return startTimeAfterSelectedDate && isAvailable;
});
var getSortedHours = (timeSlots) => timeSlots.reduce(
  (acc, timeSlot) => {
    const startHour = parseInt(
      (0, import_date_fns_tz2.formatInTimeZone)(new Date(timeSlot.startTime), timeZone, "HH")
    );
    const isMorning = startHour < 12;
    const isAfternoon = startHour >= 12 && startHour < 18;
    if (isMorning) {
      return { ...acc, morning: [...acc.morning, timeSlot] };
    } else if (isAfternoon) {
      return { ...acc, afternoon: [...acc.afternoon, timeSlot] };
    } else {
      return { ...acc, evening: [...acc.evening, timeSlot] };
    }
  },
  { morning: [], afternoon: [], evening: [] }
);

// src/hooks/useWeekRanges.ts
function useWeekRanges({
  startDate,
  endDate,
  daysAhead,
  setSelectedDate,
  setStartDate,
  setEndDate,
  selectedDay
}) {
  const [currentWeekIndex, setCurrentWeekIndex] = (0, import_react6.useState)(0);
  const [weeks, setWeeks] = (0, import_react6.useState)([getWeek(startDate, daysAhead)]);
  const previousRange = usePrevious(daysAhead);
  const firstDayOfWeek = weeks?.[currentWeekIndex][0].date;
  const lastDayOfWeek = (0, import_date_fns3.endOfDay)((0, import_date_fns3.addDays)(firstDayOfWeek, daysAhead - 1));
  const next = () => {
    const nextIndex = currentWeekIndex + 1;
    const nextWeekInDates = weeks[nextIndex];
    if (nextWeekInDates && nextWeekInDates.length === daysAhead) {
      setEndDate((0, import_date_fns3.endOfDay)(nextWeekInDates[nextWeekInDates.length - 1].date));
      setSelectedDate(nextWeekInDates[0].formatted.dateString);
      setStartDate(nextWeekInDates[0].date);
      setCurrentWeekIndex(nextIndex);
      return;
    }
    const currentWeek = weeks[currentWeekIndex];
    const nextWeekStart = (0, import_date_fns3.addDays)(currentWeek[currentWeek?.length - 1].date, 1);
    const nextWeek = getWeek(nextWeekStart, daysAhead);
    setCurrentWeekIndex(nextIndex);
    setWeeks([...weeks, nextWeek]);
    setSelectedDate(nextWeek[0].formatted.dateString);
    setStartDate((0, import_date_fns3.startOfDay)(nextWeek[0].date));
    setEndDate((0, import_date_fns3.endOfDay)(nextWeek[nextWeek.length - 1].date));
  };
  const prev = () => {
    const getPrevWeek = () => {
      if (currentWeekIndex === 0) {
        const firstDayOfPrevWeek = (0, import_date_fns3.subDays)(weeks[0][0].date, daysAhead);
        const prevWeek2 = getWeek(firstDayOfPrevWeek, daysAhead);
        return { nextIndex: 0, prevWeek: prevWeek2 };
      } else {
        const nextIndex2 = currentWeekIndex - 1;
        return { nextIndex: nextIndex2, prevWeek: weeks[nextIndex2] };
      }
    };
    const { prevWeek, nextIndex } = getPrevWeek();
    if (nextIndex === 0) {
      setWeeks([prevWeek, ...weeks]);
    }
    setCurrentWeekIndex(nextIndex);
    setSelectedDate(prevWeek[0].formatted.dateString);
    setStartDate((0, import_date_fns3.startOfDay)(prevWeek[0].date));
    setEndDate((0, import_date_fns3.endOfDay)(prevWeek[prevWeek.length - 1].date));
  };
  const canGoBack = getDateInTimezone(startDate) > getDateInTimezone(/* @__PURE__ */ new Date());
  const jumpToDate = (date) => {
    const parsedDate = parseDay(date);
    if (parsedDate < /* @__PURE__ */ new Date()) {
      return;
    }
    if (startDate < parsedDate && parsedDate < endDate) {
      setSelectedDate(date);
    } else {
      const newWeek = getWeek(parsedDate, daysAhead);
      setWeeks([newWeek]);
      setCurrentWeekIndex(0);
      setSelectedDate(date);
      setStartDate((0, import_date_fns3.startOfDay)(parsedDate));
      setEndDate((0, import_date_fns3.endOfDay)(newWeek[newWeek.length - 1].date));
    }
  };
  (0, import_react6.useEffect)(() => {
    if (previousRange && previousRange !== daysAhead) {
      const flattened = weeks.slice().flat();
      const chunked = (0, import_chunk.default)(flattened, daysAhead);
      const nextIndex = chunked.findIndex(
        (week) => week.find((day) => day.formatted.dateString === selectedDay)
      );
      if (chunked[chunked.length - 1].length < daysAhead) {
        const lastWeek = chunked[chunked.length - 1];
        const lastDate = lastWeek[lastWeek.length - 1].date;
        const nextWeek = getWeek(
          (0, import_date_fns3.addDays)(lastDate, 1),
          daysAhead - chunked[chunked.length - 1].length
        );
        chunked[chunked.length - 1].push(...nextWeek);
        setWeeks(chunked);
        setSelectedDate(selectedDay);
        setCurrentWeekIndex(nextIndex);
        setStartDate(chunked[0][0].date);
        setEndDate(lastDayOfWeek);
        return;
      }
      setCurrentWeekIndex(nextIndex);
      setSelectedDate(selectedDay);
      setStartDate(chunked[0][0].date);
      setEndDate(lastDayOfWeek);
      setWeeks(chunked);
    }
  }, [daysAhead]);
  return {
    prev,
    next,
    setCurrentWeekIndex,
    currentWeekIndex,
    weeks,
    jumpToDate,
    canGoBack
  };
}
var getWeek = (startDate, daysAhead) => {
  return (0, import_date_fns3.eachDayOfInterval)({
    start: startDate,
    end: (0, import_date_fns3.addDays)(startDate, daysAhead - 1)
  }).map((day) => ({
    date: day,
    formatted: {
      iso: day.toISOString(),
      dateString: (0, import_date_fns3.format)(day, "yyyy-MM-dd", { locale: import_nb3.default }),
      day: (0, import_date_fns3.format)(day, "dd", { locale: import_nb3.default }),
      month: (0, import_date_fns3.format)(day, "MM", { locale: import_nb3.default }),
      year: (0, import_date_fns3.format)(day, "yyyy", { locale: import_nb3.default })
    }
  })).sort((a, b) => a.date.getTime() - b.date.getTime());
};

// src/lib/types.ts
var z = __toESM(require("zod"));
var FeedbackSchema = z.object({
  //  email: z.string().email('Enter a real email please.').optional(), // renders TextField
  message: z.string()
});
var HelpSchema = z.object({
  // email: z.string().email('Enter a real email please.'),
  message: z.string()
});

// src/stores/useBannerStore.ts
var import_isSameDay = __toESM(require("date-fns/isSameDay"));
var import_zustand = require("zustand");
var useBannerStore = (0, import_zustand.create)()((set, state) => ({
  bannerState: "hidden",
  openBanner: () => set({ bannerState: "open" }),
  closeBanner: () => set({ bannerState: "closed" }),
  formState: "",
  setFormState: (formState) => set({ formState }),
  lastAnswered: "",
  setLastAnswered: () => set({ lastAnswered: (/* @__PURE__ */ new Date()).toISOString() }),
  wasLastAnsweredToday: () => {
    const la = state().lastAnswered;
    if (la === "" || !isIsoDate(la))
      return false;
    return (0, import_isSameDay.default)(/* @__PURE__ */ new Date(), new Date(la));
  }
}));

// src/stores/useClinicStore.ts
var import_zustand2 = require("zustand");
var import_middleware = require("zustand/middleware");
var useClinicStore = (storage) => (0, import_zustand2.create)()(
  (0, import_middleware.persist)(
    (set, get) => ({
      clinics: [],
      clinic: void 0,
      cityTree: {},
      clinicId: "",
      setClinicTree: (clinics) => {
        const cityTree = clinics.reduce((acc, clinic) => {
          const city = clinic.address?.city;
          return {
            ...acc,
            [city]: acc[city] ? [...acc[city], clinic] : [clinic]
          };
        }, {});
        set(() => ({ cityTree }));
      },
      setClinics: (clinics) => {
        set(() => ({ clinics }));
        get().setClinicTree(clinics);
      },
      setClinicId: (clinicId) => set(() => ({ clinicId })),
      setClinic: (clinic) => {
        set(() => ({ clinic }));
      },
      resetClinicStore: () => set(() => ({
        clinic: void 0,
        cityTree: {},
        clinicId: ""
      })),
      getClinic: (shortId) => {
        const clinics = get().clinics;
        return clinics.find((clinic) => clinic.shortId === shortId);
      }
    }),
    {
      name: "clinicStore",
      storage: (0, import_middleware.createJSONStorage)(() => storage || sessionStorage)
    }
  )
);

// src/stores/useCookieStore.ts
var import_zustand3 = require("zustand");
var import_middleware2 = require("zustand/middleware");
var useCookieStore = (0, import_zustand3.create)()(
  (0, import_middleware2.persist)(
    (set) => ({
      posthogId: "",
      setPosthogId: (state) => set({ posthogId: state }),
      isPosthogEnabled: false,
      setPosthogEnabled: (state) => set({ isPosthogEnabled: state })
    }),
    {
      name: "cookie-storage",
      storage: (0, import_middleware2.createJSONStorage)(() => sessionStorage)
    }
  )
);

// src/stores/useDateStore.ts
var import_zustand4 = require("zustand");
var import_middleware3 = require("zustand/middleware");
var useDateStore = (storage) => (0, import_zustand4.create)()(
  (0, import_middleware3.persist)(
    (set) => ({
      selectedDate: getDateInTimezone(),
      setSelectedDate: (date) => set({ selectedDate: date })
    }),
    {
      name: "dateStore",
      storage: (0, import_middleware3.createJSONStorage)(() => storage || sessionStorage)
    }
  )
);

// src/stores/useNavigationStore.ts
var import_isEmpty = __toESM(require("lodash/isEmpty"));
var import_zustand5 = require("zustand");
var useNavigationStore = (0, import_zustand5.create)()(
  (set, get) => ({
    isInit: false,
    queries: {},
    currStep: !(0, import_isEmpty.default)(get()?.queries) ? Object.keys?.(get()?.queries).length : 0,
    setCurrentStep: (step) => set(() => ({ currStep: step })),
    setIsInit: (flag) => set(() => ({ isInit: flag })),
    goToStep: (step) => set(() => ({ currStep: step })),
    advanceStep: () => {
      set((state) => ({
        currStep: state.currStep + 1
      }));
    },
    getPreviousStep: () => {
      const prevStep = get().currStep - 1;
      const allStepsTilNow = [
        ...Object.keys(getAllParamsUpTo(prevStep)),
        ...Object.keys(globalParams)
      ];
      const prevQuery = get().queries;
      const queriesOnPrevStep = allStepsTilNow.reduce((acc, key) => {
        if (prevQuery[key]) {
          acc[key] = prevQuery[key];
        }
        return acc;
      }, {});
      set(() => ({ queries: queriesOnPrevStep }));
      set((state) => ({ currStep: state.currStep - 1 }));
    },
    setQueries: (query) => {
      set((state) => ({ queries: { ...state.queries, ...query } }));
    },
    removeQueries: () => set(() => ({ queries: {}, currStep: 0 }))
  })
);

// src/stores/useNotificationStore.ts
var import_zustand6 = require("zustand");
var import_middleware4 = require("zustand/middleware");
var useNotificationStore = (0, import_zustand6.create)()(
  (0, import_middleware4.persist)(
    (set) => ({
      isNotificationVisible: false,
      openNotification: () => set({ isNotificationVisible: true }),
      closeNotification: () => set({ isNotificationVisible: false })
    }),
    { name: "notification", storage: (0, import_middleware4.createJSONStorage)(() => sessionStorage) }
  )
);

// src/stores/usePartnerStore.ts
var import_zustand7 = require("zustand");
var usePartnerStore = (0, import_zustand7.create)()((set, get) => ({
  membershipCode: "",
  myMemberships: [],
  partnerList: [],
  selectedMembership: {},
  selectedMembershipCampaign: {},
  selectedPartner: {},
  selectedType: "owner",
  setMembershipCode: (membershipCode) => set(() => ({ membershipCode })),
  setMyMemberships: (myMemberships) => set(() => ({ myMemberships })),
  setPartnerList: (partnerList) => set(() => ({ partnerList })),
  setSelectedMembership: (membership) => set(() => ({ selectedMembership: membership })),
  setSelectedMembershipCampaign: (membershipCampaign) => set(() => ({ selectedMembershipCampaign: membershipCampaign })),
  setSelectedPartner: (partner) => set(() => ({ selectedPartner: partner })),
  setSelectedType: (type) => set(() => ({ selectedType: type })),
  getPartner: (partnerId) => {
    const { partnerList } = get();
    return partnerList.find((partner) => partner.id === partnerId);
  }
}));

// src/stores/usePaymentStore.ts
var import_zustand8 = require("zustand");
var usePaymentStore = (0, import_zustand8.create)(
  (set) => ({
    paymentType: [],
    setPaymentType: (paymentType) => set({ paymentType }),
    clearPaymentType: () => set({ paymentType: [] })
  })
);

// src/stores/usePersonalDataStore.ts
var import_zustand9 = require("zustand");
var import_middleware5 = require("zustand/middleware");
var usePersonalDataStore = (storage) => (0, import_zustand9.create)()(
  (0, import_middleware5.persist)(
    (set) => ({
      personalDetails: {},
      setPersonalDetails: (data) => set((state) => ({
        personalDetails: { ...state.personalDetails, ...data }
      })),
      clearPersonalDetails: () => set(() => ({ personalDetails: {} }))
    }),
    {
      name: "personalDetails",
      storage: (0, import_middleware5.createJSONStorage)(() => storage || sessionStorage)
    }
  )
);

// src/stores/usePositionStore.ts
var import_zustand10 = require("zustand");
var import_middleware6 = require("zustand/middleware");
var usePositionStore = (0, import_zustand10.create)()(
  (0, import_middleware6.persist)(
    (set) => ({
      currentCoords: void 0,
      setCurrentCoords: (currentCoords) => set({ currentCoords }),
      getLocation: ({ onSuccess, onError }) => {
        const success = (position) => {
          set({ currentCoords: position.coords });
          onSuccess();
        };
        const error = () => {
          set({ currentCoords: void 0 });
          onError();
        };
        if (navigator) {
          navigator.geolocation.getCurrentPosition(success, error);
        }
      }
    }),
    {
      name: "geoPermission",
      storage: (0, import_middleware6.createJSONStorage)(() => localStorage)
    }
  )
);

// src/stores/usePractitionerStore.ts
var import_zustand11 = require("zustand");
var usePractitionerStore = (0, import_zustand11.create)()((set, get) => ({
  practitioners: [],
  selectedPractitioner: null,
  showAllPractitioners: true,
  setPractitioners: (practitioners) => set(() => ({ practitioners })),
  setSelectedPractitioner: (practitioner) => set(() => ({ selectedPractitioner: practitioner })),
  setShowAllPractitioners: (showAllPractitioners) => set(() => ({ showAllPractitioners }))
}));

// src/stores/useServiceTreeStore.ts
var import_zustand12 = require("zustand");
var useServiceTreeStore = (0, import_zustand12.create)()((set, get) => ({
  serviceTree: [],
  serviceMap: /* @__PURE__ */ new Map(),
  specialities: [],
  links: [],
  getLinksByVerticalShortId: (shortId) => {
    const { serviceTree } = get();
    return serviceTree.find((speciality) => speciality.shortId === shortId)?.links;
  },
  getServiceByShortId: (shortId) => {
    const serviceMap = get().serviceMap;
    if (serviceMap.size > 0)
      return serviceMap.get(shortId);
  },
  getServiceUuid: (shortId) => {
    const service = get().getServiceByShortId(shortId);
    return service?.id || "";
  },
  getServiceHasSurchargeForShortId: (shortId) => {
    return getServiceHasSurcharge(get().getServiceByShortId(shortId));
  },
  getSurcharge: (shortId, dayType = "day", isEvening = false, useFallbackResult = false) => {
    const fallbackResult = {
      surchargePrice: 0,
      surchargeType: void 0
    };
    if (!shortId || useFallbackResult || !couldHaveSurcharge(dayType, isEvening))
      return fallbackResult;
    const service = get().getServiceByShortId(shortId);
    if (!getServiceHasSurcharge(service)) {
      return fallbackResult;
    }
    const isWeekendOrHoliday = dayType === "holiday" || dayType === "weekend";
    const weekendSurcharge = service?.weekendSurcharge ?? 0;
    const eveningSurcharge = service?.eveningSurcharge ?? 0;
    if (isWeekendOrHoliday && weekendSurcharge > 0) {
      return {
        surchargePrice: weekendSurcharge,
        surchargeType: dayType
      };
    }
    if (isEvening && eveningSurcharge > 0) {
      return {
        surchargePrice: eveningSurcharge,
        surchargeType: "evening"
      };
    }
    return fallbackResult;
  },
  setServiceTree: (serviceTree) => {
    set(() => ({
      serviceTree
    }));
    const serviceMap = /* @__PURE__ */ new Map();
    serviceTree.map(({ subCategories, services }) => {
      return subCategories.map(({ services: services2 }) => services2).concat(services).flat();
    }).flat().forEach((service) => {
      return serviceMap.set(service.shortId, service);
    });
    set(() => ({ serviceMap }));
  },
  getSpecialities: (vertical) => {
    const { serviceTree } = get();
    const specialities = serviceTree.find(({ shortId }) => shortId === vertical) || {};
    if (!specialities.subCategories || !specialities.services)
      return [];
    const result = [...specialities.subCategories];
    specialities.services.forEach(
      (service) => result.push({
        ...specialities,
        id: service.id,
        name: service.name,
        shortId: service.shortId,
        services: [service],
        order: service.order
      })
    );
    return result.sort((a, b) => a.order - b.order);
  },
  getServiceName: (serviceId, locale) => {
    const serviceMap = get().serviceMap;
    return serviceMap.get(serviceId)?.name[locale];
  },
  getSpecialityName(verticalId, categoryId, locale) {
    const serviceTree = get().serviceTree;
    const vertical = serviceTree.find(({ shortId }) => shortId === verticalId);
    const speciality = vertical?.subCategories.find(
      ({ shortId }) => shortId === categoryId
    );
    const name = speciality?.name[locale];
    if (name)
      return name;
    return vertical?.name[locale];
  },
  getServicePrice: (shortId) => {
    const serviceMap = get().serviceMap;
    if (serviceMap.size <= 0)
      return void 0;
    return serviceMap.get(shortId)?.price;
  },
  // This is a way of getting all clinic ids from the service tree
  // and is used to match clinic ids with the currently selected service
  getClinicIdsFromServiceTree: (serviceId) => {
    const clinicIds = get().serviceMap && "get" in get().serviceMap && get().serviceMap.get(serviceId)?.clinicIds || [];
    return clinicIds;
  }
}));

// src/stores/useUIStore.ts
var import_zustand13 = require("zustand");
var useUIStore = (0, import_zustand13.create)()((set) => ({
  errorTitle: "",
  errorMessage: "",
  errorVisible: false,
  setError: (title, message) => {
    set(() => ({
      errorTitle: title,
      errorMessage: message,
      errorVisible: true
    }));
  },
  hideError: () => set({ errorVisible: false })
}));
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ALL_STEPS,
  FeedbackSchema,
  HelpSchema,
  allQueryParams,
  appLocales,
  clinicsWithDistance,
  convertDistanceToUnit,
  couldHaveSurcharge,
  findNextAvailableTimeSlot,
  findNextAvailableTimeSlotByPractitioner,
  formatDate,
  formatPrice,
  formatReadableDateTime,
  formatStartTime,
  getAllParams,
  getAllParamsUpTo,
  getAvailableTimeSlotOnSelectedDay,
  getCookieConsent,
  getDateInTimezone,
  getDateTypeOfDay,
  getDayTypeAndTimeOfDayFromSelectedTime,
  getDaysUntil,
  getDistanceToClinics,
  getFormattedAppointmentDateTime,
  getFormattedBirthDate,
  getFormattedCardNumber,
  getFormattedPhoneNumber,
  getFormattedSSN,
  getFormattedWaitTime,
  getISOString,
  getIsEvening,
  getLowestPrice,
  getNorwegianSurchargeInfoText,
  getNorwegianSurchargeLabel,
  getPractitionerTimeSlots,
  getPractitionersWithSortedTimeSlotsForSelectedDate,
  getServiceHasSurcharge,
  getSortedHours,
  getStepFilledToNow,
  getTimeStamp,
  getUniqueDayList,
  getVertical,
  globalParams,
  isIsoDate,
  isPhoneNumberGoodEnough,
  isTimeSlotInSelectedDay,
  isTimeslotInTheFuture,
  locales,
  localesDateOnly,
  norwayTimeZone,
  parseDay,
  stepParams,
  timeZone,
  useBannerStore,
  useClinicStore,
  useCookieStore,
  useDateStore,
  useEventListener,
  useIntersectionObserver,
  useIsomorphicLayoutEffect,
  useNavigationStore,
  useNotificationStore,
  usePartnerStore,
  usePaymentStore,
  usePersonalDataStore,
  usePositionStore,
  usePractitionerStore,
  usePrevious,
  useServiceTreeStore,
  useToAndEndDate,
  useUIStore,
  useWeekRanges
});
